import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const firmast = () => (
  <Layout>
    <SEO title="Kontakt" />
    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>Firmast</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>
    <div className="contact">
      Winterland Service OÜ tegeleb epoksiidpõrandate paigaldamisega peamiselt
      firmadele, kuid ka eraisikutele. Seoses kehtivate normidega
      toiduainetööstustele kasutatakse epopõrandaid tootmisruumides pea
      sajaprotsendiliselt. Samuti on hakatud kasutama epokatteid garaažides,
      lao- ja ühiskondlikes ruumides. Seda nende heade omaduste ja soodsa hinna
      pärast. Algmaterjalidena kasutame muuhulgas firma Caparol (Saksamaa)
      epoksiidvaike. Materjalid tarnime Caparol Baltica Eesti filiaali kaudu,
      kellega oleme teinud positiivset koostööd meie tegevuse algusest alates.
      Lisaks kasutame Tikkurila, Epokate, Teknos, Nanten jt tootjate
      epovaikusid. Tänu algmaterjalide eripärale ja positiivsetele
      töötulemustele on tellimuste arv tegevusaja jooksul mitmekordistunud.
      Tuginedes headele varasematele koostöö kontaktidele suuremate
      ehitusfirmadega (Merko Ehitus, Ehitus5Eco, Nordecon, YIT AS jne), oleme
      alustanud aktiivset tööd heade majandustulemustega. Samas oleme loonud
      uusi kontakte potentsiaalsete klientidega nii väiksemate ehitusfirmade kui
      eraisikute hulgas. Meie firmas töötab suurte müügi- ja ehituskogemustega
      müügijuht ja ehitusjuhina suurte kogemustega epopõrandate spetsialist.
      <h3
        style={{
          color: `#6a6aff`,
        }}
      >
        Winterland Service OÜ
      </h3>
      <ul>
        <li>Reg. kood: 11553475</li>
        <li>KMKR: EE101283124</li>
        <li>Harjumaa, Tallinn, J. Kunderi tn 27-11, 10121</li>
        <li>E-mail: info@epo.ee</li>
        <li>GSM: +372 53480606</li>
      </ul>
    </div>
  </Layout>
)

export default firmast
